@import '~antd/dist/antd.css';

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-overflow-scrolling: 'none';
  scrollbar-width: 0;
  overflow: hidden;
}

.App-content {
  flex: 1;
  overflow: hidden;
}

.ant-message {
  bottom: 20px;
  top: initial;
}
